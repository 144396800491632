var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$tc('title.filtro', 2)))]),_c('v-spacer'),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.mostrarFiltros),expression:"mostrarFiltros"}],class:_vm.filtrosFixosClass,on:{"click":_vm.fixaFiltros}},[_vm._v(" fa-thumbtack ")]),_c('v-icon',{on:{"click":_vm.toggleMostrarFiltros}},[_vm._v("filter_list")])],1),_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.mostrarFiltros),expression:"mostrarFiltros"}],attrs:{"fluid":"","grid-list-md":""}},[_c('metadados-container-layout',{ref:"container",attrs:{"ordenacao-campos":_vm.ordenacaoCampos,"campos-formulario":_vm.camposFormulario,"formulario-filtros":true,"ignora-obrigatorios":false,"layout-class":_vm.layoutClass,"input-layout":_vm.inputLayout,"objeto":_vm.filtros}})],1),_c('v-card-actions',{directives:[{name:"show",rawName:"v-show",value:(_vm.mostrarFiltros),expression:"mostrarFiltros"}]},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"accent"},on:{"click":_vm.resetaFiltros}},[_vm._v(_vm._s(_vm.$t('label.limpar_filtros')))]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.filtrar}},[_vm._v(_vm._s(_vm.$t('label.filtrar')))])],1)],1),_c('v-card',{staticClass:"mb-4"},[_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{staticClass:"pl-8",attrs:{"cols":"12","sm":"8","md":"8","align-self":"center"}},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$tc("label.projeto", 1))+" ")]),(_vm.canAccessCRUD && _vm.selected.length)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"id":"btn_ativar_inativar","icon":""},on:{"click":function($event){return _vm.ativarInativar()}}},on),[_c('v-icon',[_vm._v("block")])],1)]}}],null,false,1526550347)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.ativar_inativa')))])]):_vm._e()],1)],1),_c('v-data-table',{staticClass:"custom-pagination-left",attrs:{"id":"tabela_dados_projeto","headers":_vm.headers,"items":_vm.projetos,"show-select":_vm.canAccessCRUD,"options":_vm.pagination,"server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
      itemsPerPageOptions: [10, 25, 50],
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
    var isSelected = ref.isSelected;
    var select = ref.select;
return [_c('tr',{key:item.id},[(_vm.canAccessCRUD)?_c('td',[_c('v-simple-checkbox',{attrs:{"value":_vm.canAccessCRUD && isSelected},on:{"input":function($event){return select($event)}}})],1):_vm._e(),_c('td',{staticClass:"justify-center px-0"},[(_vm.canAccessCRUD)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.editar(item)}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.editar')))])]):_vm._e(),(!_vm.canAccessCRUD)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.ver(item)}}},on),[_c('v-icon',[_vm._v("visibility")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.visualizar')))])]):_vm._e()],1),_c('td',[_vm._v(_vm._s(item.nomProjeto))]),_c('td',[_vm._v(_vm._s(item.nomCliente))]),_c('td',[_vm._v(_vm._s(item.dtaInicio))]),_c('td',[_vm._v(_vm._s(_vm.getMoney(item.vlrReservado + item.vlrConsumido)))]),_c('td',[_vm._v(_vm._s(_vm.getMoney(item.vlrConsumido)))]),_c('td',[_vm._v(_vm._s(item.indAtivo ? _vm.$t('label.ativo') : _vm.$t('label.inativo')))])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('confirm',{ref:"confirmDialog",attrs:{"message":_vm.getMessageInativar()},on:{"agree":_vm.executarAtivarInativar}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }