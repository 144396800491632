import { buscarClientes, buscarProjetosSelecao } from '../../common/resources/akzo-acao-projeto';

export default {
  name: 'ProjetoListFiltros',
  data() {
    return {
      camposFormulario: {
        padrao: [
          {
            labelCampo: 'projeto',
            nomCampoId: 'ids_projeto',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => buscarProjetosSelecao({ autocomplete }, this.$resource),
              itemValue: 'id',
              itemText: 'nomProjeto',
            },
          },
          {
            labelCampo: 'instituicao',
            nomCampoId: 'ids_cliente',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => buscarClientes({ autocomplete }, this.$resource),
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
          {
            labelCampo: 'status',
            nomCampoId: 'status',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              textoItem: 'texto',
              valorItem: 'valor',
              lista: [
                {
                  texto: this.$tc('label.ativo', 1),
                  valor: this.$tc('label.ativo', 1),
                },
                {
                  texto: this.$tc('label.inativo', 1),
                  valor: this.$tc('label.inativo', 1),
                },
              ],
            },
          },

        ],
      },
    };
  },
};
