<template>
  <div>
    <v-card class="mb-4">
      <v-card-title>
        <div class="title">{{ $tc('title.filtro', 2) }}</div>
        <v-spacer></v-spacer>
         <v-icon v-show="mostrarFiltros"
        @click="fixaFiltros"
        :class="filtrosFixosClass"
        >
        fa-thumbtack
      </v-icon>
        <v-icon @click="toggleMostrarFiltros">filter_list</v-icon>
      </v-card-title>
      <v-container fluid grid-list-md v-show="mostrarFiltros">
        <metadados-container-layout
          :ordenacao-campos="ordenacaoCampos"
          :campos-formulario="camposFormulario"
          :formulario-filtros="true"
          :ignora-obrigatorios="false"
          :layout-class="layoutClass"
          :input-layout="inputLayout"
          :objeto="filtros"
          ref="container">
        </metadados-container-layout>
      </v-container>
      <v-card-actions v-show="mostrarFiltros">
        <v-spacer></v-spacer>
        <v-btn @click="resetaFiltros"
          color="accent"
        >{{$t('label.limpar_filtros')}}</v-btn>
        <v-btn @click="filtrar"
          color="primary"
        >{{$t('label.filtrar')}}</v-btn>
      </v-card-actions>
    </v-card>

   <v-card class="mb-4">
    <v-row justify="start">
     <v-col class="pl-8" cols="12" sm="8" md="8" align-self="center">

        <span class="title">{{ $tc("label.projeto", 1) }} </span>

        <v-tooltip bottom v-if="canAccessCRUD && selected.length">
            <template v-slot:activator="{ on }">
              <v-btn id="btn_ativar_inativar" icon class="mx-0" v-on="on" @click="ativarInativar()">
                <v-icon>block</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.ativar_inativa') }}</span>
          </v-tooltip>
      </v-col>
    </v-row>

    <v-data-table
      id="tabela_dados_projeto"
      v-model="selected"
      :headers="headers"
      :items="projetos"
      :show-select="canAccessCRUD"
      class="custom-pagination-left"
      :options.sync="pagination"
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50],
      }"
      >
      <template v-slot:item="{ item, isSelected, select  }">
        <tr :key="item.id">
          <td v-if="canAccessCRUD">
            <v-simple-checkbox :value="canAccessCRUD && isSelected" @input="select($event)"></v-simple-checkbox>
          </td>
          <td class="justify-center px-0">
            <v-tooltip bottom v-if="canAccessCRUD" >
              <template v-slot:activator="{ on }">
                <v-btn icon class="mx-0" v-on="on" @click.stop="editar(item)">
                  <v-icon>edit</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('label.editar') }}</span>
            </v-tooltip>
            <v-tooltip bottom  v-if="!canAccessCRUD">
              <template v-slot:activator="{ on }">
                <v-btn icon class="mx-0" v-on="on" @click="ver(item)">
                  <v-icon>visibility</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('label.visualizar') }}</span>
            </v-tooltip>
          </td>
          <td>{{ item.nomProjeto }}</td>
          <td>{{ item.nomCliente}}</td>
          <td>{{ item.dtaInicio}}</td>
          <td>{{ getMoney(item.vlrReservado + item.vlrConsumido)}}</td>
          <td>{{ getMoney(item.vlrConsumido)}}</td>
          <td>{{ item.indAtivo ? $t('label.ativo') : $t('label.inativo') }}</td>
        </tr>
      </template>
    </v-data-table>
   </v-card>
    <confirm
        ref="confirmDialog"
        :message="getMessageInativar()"
        @agree="executarAtivarInativar">
    </confirm>
  </div>
</template>
<script>

import MetadadosContainerLayout from '../../produto/shared-components/metadados/MetadadosContainerLayout';
import Confirm from '../../produto/shared-components/vuetify/dialog/Confirm';
import { skipLoading } from '../../produto/common/functions/loading';
import { getMoney } from '../../produto/common/functions/helpers';
import ProjetoListFiltros from './ProjetoListFiltros';
import { buscarProjetos } from '../../common/resources/akzo-acao-projeto';

export default {
  name: 'ProjetoListDados',
  mixins: [
    ProjetoListFiltros,
  ],
  props: {
    canAccessCRUD: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      workspaceFiltrosResources: this.$api.workspaceFiltros(this.$resource),
      projetoResources: this.$api.akzoAcaoProjeto(this.$resource),
      projetos: [],
      selected: [],

      esperar: false,
      pagination: {},
      totalPage: 0,
      filtros: {},
      mostrarFiltros: false,
      filtrosAbertos: false,
      filtrosFixados: false,
      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: false,
        md4: true,
        md6: false,
        md9: false,
      },
      ordenacaoCampos: [
        'projeto',
        'instituicao',
        'status',
      ],

      headers: [
        {
          text: '', value: 'acoes', sortable: false, width: '100', align: 'center',
        },
        {
          text: this.$tc('label.nom_projeto', 1), value: 'nom_projeto', sortable: true, width: 250,
        },
        {
          text: this.$tc('label.instituicao', 1), value: 'nom_cliente', sortable: true, width: 250,
        },
        {
          text: this.$tc('label.dta_de_inicio_projeto', 1), value: 'dta_incio', sortable: true, width: 250,
        },
        {
          text: this.$tc('label.vlr_investido', 1), value: 'vlr_investido', sortable: false, width: 250,
        },
        {
          text: this.$tc('label.vlr_consumido', 1), value: 'vlr_consumido', sortable: true, width: 250,
        },
        { text: this.$tc('label.status', 1), value: 'ind_ativo', sortable: true },
      ],
    };
  },
  components: {
    MetadadosContainerLayout,
    Confirm,
  },
  computed: {
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
      };
    },
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
  },
  methods: {
    getMoney,
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 5E2);
    },
    buscar() {
      const params = {
        ...this.$refs.container.getValoresCamposPadrao(),
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };
      buscarProjetos(params, this.$resource).then((response) => {
        this.projetos = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.pararEsperar();
        this.selected = [];
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    toggleAll() {
      if (this.selected.length) {
        this.selected = [];
      } else {
        this.selected = this.projetos.slice();
      }
    },
    ativarInativar() {
      this.$refs.confirmDialog.open();
    },
    getMessageInativar() {
      const nomes = this.selected.map((el) => el.nomProjeto).join(', ');
      const msg = this.$tc('message.ativar_inativar_projetos', 1);
      return msg.concat(`  ${nomes}`);
    },
    executarAtivarInativar() {
      this.projetoResources.ativarMassa(this.selected)
        .then(() => {
          this.filtrar();
          this.$toast(this.$t('message.atualizado_confira_tabela'));
        }, (err) => {
          this.$error(this, err);
        });
    },
    editar(item) {
      this.$router.push({ name: 'editarProjeto', params: { id: item.id } });
    },
    ver(item) {
      this.$router.push({ name: 'verProjeto', params: { id: item.id } });
    },
    resetaFiltros() {
      this.filtros = {};
      this.filtrar();
    },
    toggleMostrarFiltros() {
      this.mostrarFiltros = !this.mostrarFiltros;
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = 'projeto-akzo';
      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = 'projeto-akzo';
      this.workspaceFiltrosResources.atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    carregarWorkspaceFiltros() {
      const entidade = 'projeto-akzo';
      this.workspaceFiltrosResources.pesquisar({ entidade })
        .then((response) => {
          this.workspace = response.data || {};
          this.filtrosFixados = this.workspace.indAberto || false;
          this.filtrosAbertos = this.filtrosFixados;
          this.filtros = this.workspace.filtros || {};

          setTimeout(() => this.$refs.container.refresh());
          this.filtrar();
        }).catch((err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {
    this.carregarWorkspaceFiltros();
  },
};
</script>
