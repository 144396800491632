<template>
  <div >
      <projeto-list-dados :canAccessCRUD="canAccessCRUD" ref="tabelaProjeto">
      </projeto-list-dados>

     <v-card-actions v-if="canAccessCRUD">
        <v-row>
          <v-tooltip left >
            <template v-slot:activator="{ on }">
              <v-btn id="btn_novo" fab @click="novo" class="v-btn__fab" color="primary" v-on="on" bottom right fixed>
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <span>{{$t('label.novo')}}</span>
          </v-tooltip>
        </v-row>
      </v-card-actions>
  </div>
</template>
<script>

import { generateComputed } from '../../produto/common/functions/roles-computed-generator';
import ProjetoListDados from './ProjetoListDados';

export default {
  name: 'ProjetoList',
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
    };
  },
  components: {
    ProjetoListDados,
  },
  computed: {
    ...generateComputed('PROJETO_SUSTENTA', [
      'canAccessPage',
      'canAccessCRUD',
    ]),
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    novo() {
      this.$router.push({ name: 'novoProjeto' });
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
  },
};
</script>
